import React from "react";
import Dashboard from "../../../components/common/Layout/Dashboard";
import FourCardRow from "../../../components/common/Layout/FourCardRow";
import FourGraphs from "../../../components/common/Layout/FourGraphs";
import InfoCard from "../../../components/common/InfoCard/InfoCard";
import SimplePieChart from "../../../components/common/Charts/PieChart";
import LineBarChart from "../../../components/common/Charts/LineBarChart";
import CustomBarChart from "../../../components/common/Charts/CustomBarChart";
import InventoryTableChart from "../../../components/common/Charts/InventoryTableChart";
import ContentCard from "../../../components/common/ContentCard/ContentCard";
import clipboard from "../../../assets/images/clipboard.svg";
import lowStock2 from "../../../assets/images/lowStock2.svg";
import shoppingCart from "../../../assets/images/shoppingCart.svg";
import calendarSoon from "../../../assets/images/calendarSoon.svg";
import SimpleTableChartTwo from "../../../components/common/Charts/SimpleTableChartTwo";
import SingleLineChart from "../../../components/common/Charts/SingleLineChart";
import CustomStackedBarChart from "../../../components/common/Charts/CustomStackedBarChart";
import CustomInventoryTurnoverChart from "../../../components/common/Charts/CustomInventoryTurnoverChart";

const pieChartData = {
  unitMeasure: "USD",
  data: [
    { name: "Raw Goods", value: 30000 },
    { name: "Packaging", value: 50000 },
    { name: "Cleaning Supplies", value: 30000 },
    { name: "Equipment", value: 50000 },
  ],
};

const lineBarChartData = {
  unitMeasure: "USD",
  data: [
    { day: 1, month: 1, year: 2023, currentValue: 2500, forecastValue: 3000 },
    { day: 2, month: 1, year: 2023, currentValue: 2550, forecastValue: 3050 },
    { day: 3, month: 1, year: 2023, currentValue: 2600, forecastValue: 3100 },
    { day: 4, month: 1, year: 2023, currentValue: 2650, forecastValue: 3150 },
    { day: 5, month: 1, year: 2023, currentValue: 2700, forecastValue: 3200 },
    { day: 6, month: 1, year: 2023, currentValue: 2750, forecastValue: 3250 },
    { day: 7, month: 1, year: 2023, currentValue: 2800, forecastValue: 3300 },
    { day: 8, month: 1, year: 2023, currentValue: 2850, forecastValue: 3350 },
    { day: 9, month: 1, year: 2023, currentValue: 2900, forecastValue: 3400 },
    { day: 10, month: 1, year: 2023, currentValue: 2950, forecastValue: 3450 },
    { day: 11, month: 1, year: 2023, currentValue: 3000, forecastValue: 3500 },
    { day: 12, month: 1, year: 2023, currentValue: 3050, forecastValue: 3550 },
    { day: 13, month: 1, year: 2023, currentValue: 3100, forecastValue: 3600 },
    { day: 14, month: 1, year: 2023, currentValue: 3150, forecastValue: 3650 },
    { day: 15, month: 1, year: 2023, currentValue: 3200, forecastValue: 3700 },
    { day: 16, month: 1, year: 2023, currentValue: 3250, forecastValue: 3750 },
    { day: 17, month: 1, year: 2023, currentValue: 3300, forecastValue: 3800 },
    { day: 18, month: 1, year: 2023, currentValue: 3350, forecastValue: 3850 },
    { day: 19, month: 1, year: 2023, currentValue: 3400, forecastValue: 3900 },
    { day: 20, month: 1, year: 2023, currentValue: 3450, forecastValue: 3950 },
    { day: 21, month: 1, year: 2023, currentValue: 3500, forecastValue: 4000 },
    { day: 22, month: 1, year: 2023, currentValue: 3550, forecastValue: 4050 },
    { day: 23, month: 1, year: 2023, currentValue: 3600, forecastValue: 4100 },
    { day: 24, month: 1, year: 2023, currentValue: 3650, forecastValue: 4150 },
    { day: 25, month: 1, year: 2023, currentValue: 3700, forecastValue: 4200 },
    { day: 26, month: 1, year: 2023, currentValue: 3750, forecastValue: 4250 },
    { day: 27, month: 1, year: 2023, currentValue: 3800, forecastValue: 4300 },
    { day: 28, month: 1, year: 2023, currentValue: 3850, forecastValue: 4350 },
    { day: 29, month: 1, year: 2023, currentValue: 3900, forecastValue: 4400 },
    { day: 30, month: 1, year: 2023, currentValue: 3950, forecastValue: 4450 },
    { day: 31, month: 1, year: 2023, currentValue: 4000, forecastValue: 4500 },
    { day: 1, month: 2, year: 2023, currentValue: 4050, forecastValue: 4550 },
    { day: 2, month: 2, year: 2023, currentValue: 4100, forecastValue: 4600 },
    { day: 3, month: 2, year: 2023, currentValue: 4150, forecastValue: 4650 },
    { day: 4, month: 2, year: 2023, currentValue: 4200, forecastValue: 4700 },
    { day: 5, month: 2, year: 2023, currentValue: 4250, forecastValue: 4750 },
    { day: 6, month: 2, year: 2023, currentValue: 4300, forecastValue: 4800 },
    { day: 7, month: 2, year: 2023, currentValue: 4350, forecastValue: 4850 },
    { day: 8, month: 2, year: 2023, currentValue: 4400, forecastValue: 4900 },
    { day: 9, month: 2, year: 2023, currentValue: 4450, forecastValue: 4950 },
    { day: 10, month: 2, year: 2023, currentValue: 4500, forecastValue: 5000 },
    { day: 11, month: 2, year: 2023, currentValue: 4550, forecastValue: 5050 },
    { day: 12, month: 2, year: 2023, currentValue: 4600, forecastValue: 5100 },
    { day: 13, month: 2, year: 2023, currentValue: 4650, forecastValue: 5150 },
    { day: 14, month: 2, year: 2023, currentValue: 4700, forecastValue: 5200 },
    { day: 15, month: 2, year: 2023, currentValue: 4750, forecastValue: 5250 },
    { day: 16, month: 2, year: 2023, currentValue: 4800, forecastValue: 5300 },
    { day: 17, month: 2, year: 2023, currentValue: 4850, forecastValue: 5350 },
    { day: 18, month: 2, year: 2023, currentValue: 4900, forecastValue: 5400 },
    { day: 19, month: 2, year: 2023, currentValue: 4950, forecastValue: 5450 },
    { day: 20, month: 2, year: 2023, currentValue: 5000, forecastValue: 5500 },
    { day: 21, month: 2, year: 2023, currentValue: 5050, forecastValue: 5550 },
    { day: 22, month: 2, year: 2023, currentValue: 5100, forecastValue: 5600 },
    { day: 23, month: 2, year: 2023, currentValue: 5150, forecastValue: 5650 },
    { day: 24, month: 2, year: 2023, currentValue: 5200, forecastValue: 5700 },
    { day: 25, month: 2, year: 2023, currentValue: 5250, forecastValue: 5750 },
    { day: 26, month: 2, year: 2023, currentValue: 5300, forecastValue: 5800 },
    { day: 27, month: 2, year: 2023, currentValue: 5350, forecastValue: 5850 },
    { day: 28, month: 2, year: 2023, currentValue: 5400, forecastValue: 5900 },
    { day: 1, month: 3, year: 2023, currentValue: 5450, forecastValue: 5950 },
    { day: 2, month: 3, year: 2023, currentValue: 5500, forecastValue: 6000 },
    { day: 3, month: 3, year: 2023, currentValue: 5550, forecastValue: 6050 },
    { day: 4, month: 3, year: 2023, currentValue: 5600, forecastValue: 6100 },
    { day: 5, month: 3, year: 2023, currentValue: 5650, forecastValue: 6150 },
    { day: 6, month: 3, year: 2023, currentValue: 5700, forecastValue: 6200 },
    { day: 7, month: 3, year: 2023, currentValue: 5750, forecastValue: 6250 },
    { day: 8, month: 3, year: 2023, currentValue: 5800, forecastValue: 6300 },
    { day: 9, month: 3, year: 2023, currentValue: 5850, forecastValue: 6350 },
    { day: 10, month: 3, year: 2023, currentValue: 5900, forecastValue: 6400 },
    { day: 11, month: 3, year: 2023, currentValue: 5950, forecastValue: 6450 },
    { day: 12, month: 3, year: 2023, currentValue: 6000, forecastValue: 6500 },
    { day: 13, month: 3, year: 2023, currentValue: 6050, forecastValue: 6550 },
    { day: 14, month: 3, year: 2023, currentValue: 6100, forecastValue: 6600 },
    { day: 15, month: 3, year: 2023, currentValue: 6150, forecastValue: 6650 },
    { day: 16, month: 3, year: 2023, currentValue: 6200, forecastValue: 6700 },
    { day: 17, month: 3, year: 2023, currentValue: 6250, forecastValue: 6750 },
    { day: 18, month: 3, year: 2023, currentValue: 6300, forecastValue: 6800 },
    { day: 19, month: 3, year: 2023, currentValue: 6350, forecastValue: 6850 },
    { day: 20, month: 3, year: 2023, currentValue: 6400, forecastValue: 6900 },
    { day: 21, month: 3, year: 2023, currentValue: 6450, forecastValue: 6950 },
    { day: 22, month: 3, year: 2023, currentValue: 6500, forecastValue: 7000 },
    { day: 23, month: 3, year: 2023, currentValue: 6550, forecastValue: 7050 },
    { day: 24, month: 3, year: 2023, currentValue: 6600, forecastValue: 7100 },
    { day: 25, month: 3, year: 2023, currentValue: 6650, forecastValue: 7150 },
    { day: 26, month: 3, year: 2023, currentValue: 6700, forecastValue: 7200 },
    { day: 27, month: 3, year: 2023, currentValue: 6750, forecastValue: 7250 },
    { day: 28, month: 3, year: 2023, currentValue: 6800, forecastValue: 7300 },
    { day: 29, month: 3, year: 2023, currentValue: 6850, forecastValue: 7350 },
    { day: 30, month: 3, year: 2023, currentValue: 6900, forecastValue: 7400 },
    { day: 31, month: 3, year: 2023, currentValue: 6950, forecastValue: 7450 },
    { day: 1, month: 4, year: 2023, currentValue: 7000, forecastValue: 7500 },
    { day: 2, month: 4, year: 2023, currentValue: 7050, forecastValue: 7550 },
    { day: 3, month: 4, year: 2023, currentValue: 7100, forecastValue: 7600 },
    { day: 4, month: 4, year: 2023, currentValue: 7150, forecastValue: 7650 },
    { day: 5, month: 4, year: 2023, currentValue: 7200, forecastValue: 7700 },
    { day: 6, month: 4, year: 2023, currentValue: 7250, forecastValue: 7750 },
    { day: 7, month: 4, year: 2023, currentValue: 7300, forecastValue: 7800 },
    { day: 8, month: 4, year: 2023, currentValue: 7350, forecastValue: 7850 },
    { day: 9, month: 4, year: 2023, currentValue: 7400, forecastValue: 7900 },
    { day: 10, month: 4, year: 2023, currentValue: 7450, forecastValue: 7950 },
    { day: 11, month: 4, year: 2023, currentValue: 7500, forecastValue: 8000 },
    { day: 12, month: 4, year: 2023, currentValue: 7550, forecastValue: 8050 },
    { day: 13, month: 4, year: 2023, currentValue: 7600, forecastValue: 8100 },
    { day: 14, month: 4, year: 2023, currentValue: 7650, forecastValue: 8150 },
    { day: 15, month: 4, year: 2023, currentValue: 7700, forecastValue: 8200 },
    { day: 16, month: 4, year: 2023, currentValue: 7750, forecastValue: 8250 },
    { day: 17, month: 4, year: 2023, currentValue: 7800, forecastValue: 8300 },
    { day: 18, month: 4, year: 2023, currentValue: 7850, forecastValue: 8350 },
    { day: 19, month: 4, year: 2023, currentValue: 7900, forecastValue: 8400 },
    { day: 20, month: 4, year: 2023, currentValue: 7950, forecastValue: 8450 },
    { day: 21, month: 4, year: 2023, currentValue: 8000, forecastValue: 8500 },
    { day: 22, month: 4, year: 2023, currentValue: 8050, forecastValue: 8550 },
    { day: 23, month: 4, year: 2023, currentValue: 8100, forecastValue: 8600 },
    { day: 24, month: 4, year: 2023, currentValue: 8150, forecastValue: 8650 },
    { day: 25, month: 4, year: 2023, currentValue: 8200, forecastValue: 8700 },
    { day: 26, month: 4, year: 2023, currentValue: 8250, forecastValue: 8750 },
    { day: 27, month: 4, year: 2023, currentValue: 8300, forecastValue: 8800 },
    { day: 28, month: 4, year: 2023, currentValue: 8350, forecastValue: 8850 },
    { day: 29, month: 4, year: 2023, currentValue: 8400, forecastValue: 8900 },
    { day: 30, month: 4, year: 2023, currentValue: 8450, forecastValue: 8950 },
    { day: 1, month: 5, year: 2023, currentValue: 8500, forecastValue: 9000 },
    { day: 2, month: 5, year: 2023, currentValue: 8550, forecastValue: 9050 },
    { day: 3, month: 5, year: 2023, currentValue: 8600, forecastValue: 9100 },
    { day: 4, month: 5, year: 2023, currentValue: 8650, forecastValue: 9150 },
    { day: 5, month: 5, year: 2023, currentValue: 8700, forecastValue: 9200 },
    { day: 6, month: 5, year: 2023, currentValue: 8750, forecastValue: 9250 },
    { day: 7, month: 5, year: 2023, currentValue: 8800, forecastValue: 9300 },
    { day: 8, month: 5, year: 2023, currentValue: 8850, forecastValue: 9350 },
    { day: 9, month: 5, year: 2023, currentValue: 8900, forecastValue: 9400 },
    { day: 10, month: 5, year: 2023, currentValue: 8950, forecastValue: 9450 },
    { day: 11, month: 5, year: 2023, currentValue: 9000, forecastValue: 9500 },
    { day: 12, month: 5, year: 2023, currentValue: 9050, forecastValue: 9550 },
    { day: 13, month: 5, year: 2023, currentValue: 9100, forecastValue: 9600 },
    { day: 14, month: 5, year: 2023, currentValue: 9150, forecastValue: 9650 },
    { day: 15, month: 5, year: 2023, currentValue: 9200, forecastValue: 9700 },
    { day: 16, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 17, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 18, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 19, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 20, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 21, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 22, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 23, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 24, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 25, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 26, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 27, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 28, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 29, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 30, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 1, month: 6, year: 2023, currentValue: null, forecastValue: 9000 },
    { day: 2, month: 6, year: 2023, currentValue: null, forecastValue: 9050 },
    { day: 3, month: 6, year: 2023, currentValue: null, forecastValue: 9100 },
    { day: 4, month: 6, year: 2023, currentValue: null, forecastValue: 9150 },
    { day: 5, month: 6, year: 2023, currentValue: null, forecastValue: 9200 },
    { day: 6, month: 6, year: 2023, currentValue: null, forecastValue: 9250 },
    { day: 7, month: 6, year: 2023, currentValue: null, forecastValue: 9300 },
    { day: 8, month: 6, year: 2023, currentValue: null, forecastValue: 9350 },
    { day: 9, month: 6, year: 2023, currentValue: null, forecastValue: 9400 },
    { day: 10, month: 6, year: 2023, currentValue: null, forecastValue: 9450 },
    { day: 11, month: 6, year: 2023, currentValue: null, forecastValue: 9500 },
    { day: 12, month: 6, year: 2023, currentValue: null, forecastValue: 9550 },
    { day: 13, month: 6, year: 2023, currentValue: null, forecastValue: 9600 },
    { day: 14, month: 6, year: 2023, currentValue: null, forecastValue: 9650 },
    { day: 15, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 16, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 17, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 18, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 19, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 20, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 21, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 22, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 23, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 24, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 25, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 26, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 27, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 28, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 29, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 30, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
  ],
};

const generateInventoryTurnoverData = (
  startDate = new Date(2023, 0, 1),
  months = 12,
  initialTurnoverRate = 5, // Typical inventory turnover rate
  fluctuationPercentage = 0.15 // 15% fluctuation
) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return Array.from({ length: months }, (_, i) => {
    const currentDate = new Date(startDate);
    currentDate.setMonth(startDate.getMonth() + i);

    // Generate a value with random fluctuation
    const fluctuation =
      (Math.random() - 0.5) * 2 * initialTurnoverRate * fluctuationPercentage;
    const value = initialTurnoverRate + fluctuation;

    return {
      month: monthNames[currentDate.getMonth()],
      "Inventory Turnover Rate": Number(value.toFixed(2)), // Two decimal places for turnover rate
    };
  });
};

const customBarChartData = {
  unitMeasure: "lbs",
  data: [
    {
      day: 1,
      month: 1,
      year: 2023,
      Beef: 8000,
      Chicken: 9000,
      Salmon: 4000,
      Corn: 6000,
    },
    {
      day: 2,
      month: 1,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 3,
      month: 1,
      year: 2023,
      Beef: 9200,
      Chicken: 10000,
      Salmon: 4500,
      Corn: 6800,
    },
    {
      day: 4,
      month: 1,
      year: 2023,
      Beef: 6800,
      Chicken: 7800,
      Salmon: 3200,
      Corn: 4800,
    },
    {
      day: 5,
      month: 1,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 6,
      month: 1,
      year: 2023,
      Beef: 7200,
      Chicken: 8200,
      Salmon: 3600,
      Corn: 5200,
    },
    {
      day: 7,
      month: 1,
      year: 2023,
      Beef: 9500,
      Chicken: 10500,
      Salmon: 4800,
      Corn: 7000,
    },
    {
      day: 8,
      month: 1,
      year: 2023,
      Beef: 8200,
      Chicken: 9200,
      Salmon: 4100,
      Corn: 6100,
    },
    {
      day: 9,
      month: 1,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 10,
      month: 1,
      year: 2023,
      Beef: 9000,
      Chicken: 10000,
      Salmon: 4400,
      Corn: 6600,
    },
    {
      day: 11,
      month: 1,
      year: 2023,
      Beef: 7000,
      Chicken: 8000,
      Salmon: 3400,
      Corn: 5000,
    },
    {
      day: 12,
      month: 1,
      year: 2023,
      Beef: 8800,
      Chicken: 9800,
      Salmon: 4300,
      Corn: 6400,
    },
    {
      day: 13,
      month: 1,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3700,
      Corn: 5500,
    },
    {
      day: 14,
      month: 1,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 15,
      month: 1,
      year: 2023,
      Beef: 8000,
      Chicken: 9000,
      Salmon: 4000,
      Corn: 6000,
    },
    {
      day: 16,
      month: 1,
      year: 2023,
      Beef: 7200,
      Chicken: 8200,
      Salmon: 3600,
      Corn: 5200,
    },
    {
      day: 17,
      month: 1,
      year: 2023,
      Beef: 9500,
      Chicken: 10500,
      Salmon: 4800,
      Corn: 7000,
    },
    {
      day: 18,
      month: 1,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 19,
      month: 1,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 20,
      month: 1,
      year: 2023,
      Beef: 7000,
      Chicken: 8000,
      Salmon: 3400,
      Corn: 5000,
    },
    {
      day: 21,
      month: 1,
      year: 2023,
      Beef: 9000,
      Chicken: 10000,
      Salmon: 4400,
      Corn: 6600,
    },
    {
      day: 22,
      month: 1,
      year: 2023,
      Beef: 8200,
      Chicken: 9200,
      Salmon: 4100,
      Corn: 6100,
    },
    {
      day: 23,
      month: 1,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 24,
      month: 1,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 25,
      month: 1,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 26,
      month: 1,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 27,
      month: 1,
      year: 2023,
      Beef: 7200,
      Chicken: 8200,
      Salmon: 3600,
      Corn: 5200,
    },
    {
      day: 28,
      month: 1,
      year: 2023,
      Beef: 9500,
      Chicken: 10500,
      Salmon: 4800,
      Corn: 7000,
    },
    {
      day: 29,
      month: 1,
      year: 2023,
      Beef: 8000,
      Chicken: 9000,
      Salmon: 4000,
      Corn: 6000,
    },
    {
      day: 30,
      month: 1,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 31,
      month: 1,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 1,
      month: 2,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 2,
      month: 2,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 3,
      month: 2,
      year: 2023,
      Beef: 7000,
      Chicken: 8000,
      Salmon: 3400,
      Corn: 5000,
    },
    {
      day: 4,
      month: 2,
      year: 2023,
      Beef: 9000,
      Chicken: 10000,
      Salmon: 4400,
      Corn: 6600,
    },
    {
      day: 5,
      month: 2,
      year: 2023,
      Beef: 8200,
      Chicken: 9200,
      Salmon: 4100,
      Corn: 6100,
    },
    {
      day: 6,
      month: 2,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 7,
      month: 2,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 8,
      month: 2,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 9,
      month: 2,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 10,
      month: 2,
      year: 2023,
      Beef: 7200,
      Chicken: 8200,
      Salmon: 3600,
      Corn: 5200,
    },
    {
      day: 11,
      month: 2,
      year: 2023,
      Beef: 9500,
      Chicken: 10500,
      Salmon: 4800,
      Corn: 7000,
    },
    {
      day: 12,
      month: 2,
      year: 2023,
      Beef: 8000,
      Chicken: 9000,
      Salmon: 4000,
      Corn: 6000,
    },
    {
      day: 13,
      month: 2,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 14,
      month: 2,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 15,
      month: 2,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 16,
      month: 2,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 17,
      month: 2,
      year: 2023,
      Beef: 7000,
      Chicken: 8000,
      Salmon: 3400,
      Corn: 5000,
    },
    {
      day: 18,
      month: 2,
      year: 2023,
      Beef: 9000,
      Chicken: 10000,
      Salmon: 4400,
      Corn: 6600,
    },
    {
      day: 19,
      month: 2,
      year: 2023,
      Beef: 8200,
      Chicken: 9200,
      Salmon: 4100,
      Corn: 6100,
    },
    {
      day: 20,
      month: 2,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 21,
      month: 2,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 22,
      month: 2,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 23,
      month: 2,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 24,
      month: 2,
      year: 2023,
      Beef: 7200,
      Chicken: 8200,
      Salmon: 3600,
      Corn: 5200,
    },
    {
      day: 25,
      month: 2,
      year: 2023,
      Beef: 9500,
      Chicken: 10500,
      Salmon: 4800,
      Corn: 7000,
    },
    {
      day: 26,
      month: 2,
      year: 2023,
      Beef: 8000,
      Chicken: 9000,
      Salmon: 4000,
      Corn: 6000,
    },
    {
      day: 27,
      month: 2,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 28,
      month: 2,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 1,
      month: 3,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 2,
      month: 3,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 3,
      month: 3,
      year: 2023,
      Beef: 7000,
      Chicken: 8000,
      Salmon: 3400,
      Corn: 5000,
    },
    {
      day: 4,
      month: 3,
      year: 2023,
      Beef: 9000,
      Chicken: 10000,
      Salmon: 4400,
      Corn: 6600,
    },
    {
      day: 5,
      month: 3,
      year: 2023,
      Beef: 8200,
      Chicken: 9200,
      Salmon: 4100,
      Corn: 6100,
    },
    {
      day: 6,
      month: 3,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 7,
      month: 3,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 8,
      month: 3,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 9,
      month: 3,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 10,
      month: 3,
      year: 2023,
      Beef: 7200,
      Chicken: 8200,
      Salmon: 3600,
      Corn: 5200,
    },
    {
      day: 11,
      month: 3,
      year: 2023,
      Beef: 9500,
      Chicken: 10500,
      Salmon: 4800,
      Corn: 7000,
    },
    {
      day: 12,
      month: 3,
      year: 2023,
      Beef: 8000,
      Chicken: 9000,
      Salmon: 4000,
      Corn: 6000,
    },
    {
      day: 13,
      month: 3,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 14,
      month: 3,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 15,
      month: 3,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 16,
      month: 3,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 17,
      month: 3,
      year: 2023,
      Beef: 7000,
      Chicken: 8000,
      Salmon: 3400,
      Corn: 5000,
    },
    {
      day: 18,
      month: 3,
      year: 2023,
      Beef: 9000,
      Chicken: 10000,
      Salmon: 4400,
      Corn: 6600,
    },
    {
      day: 19,
      month: 3,
      year: 2023,
      Beef: 8200,
      Chicken: 9200,
      Salmon: 4100,
      Corn: 6100,
    },
    {
      day: 20,
      month: 3,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 21,
      month: 3,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 22,
      month: 3,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 23,
      month: 3,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 24,
      month: 3,
      year: 2023,
      Beef: 7200,
      Chicken: 8200,
      Salmon: 3600,
      Corn: 5200,
    },
    {
      day: 25,
      month: 3,
      year: 2023,
      Beef: 9500,
      Chicken: 10500,
      Salmon: 4800,
      Corn: 7000,
    },
    {
      day: 26,
      month: 3,
      year: 2023,
      Beef: 8000,
      Chicken: 9000,
      Salmon: 4000,
      Corn: 6000,
    },
    {
      day: 27,
      month: 3,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 28,
      month: 3,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 29,
      month: 3,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 30,
      month: 3,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 31,
      month: 3,
      year: 2023,
      Beef: 7000,
      Chicken: 8000,
      Salmon: 3400,
      Corn: 5000,
    },
    {
      day: 1,
      month: 4,
      year: 2023,
      Beef: 9000,
      Chicken: 10000,
      Salmon: 4400,
      Corn: 6600,
    },
    {
      day: 2,
      month: 4,
      year: 2023,
      Beef: 8200,
      Chicken: 9200,
      Salmon: 4100,
      Corn: 6100,
    },
    {
      day: 3,
      month: 4,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 4,
      month: 4,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 5,
      month: 4,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 6,
      month: 4,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 7,
      month: 4,
      year: 2023,
      Beef: 7200,
      Chicken: 8200,
      Salmon: 3600,
      Corn: 5200,
    },
    {
      day: 8,
      month: 4,
      year: 2023,
      Beef: 9500,
      Chicken: 10500,
      Salmon: 4800,
      Corn: 7000,
    },
    {
      day: 9,
      month: 4,
      year: 2023,
      Beef: 8000,
      Chicken: 9000,
      Salmon: 4000,
      Corn: 6000,
    },
    {
      day: 10,
      month: 4,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 11,
      month: 4,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 12,
      month: 4,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 13,
      month: 4,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 14,
      month: 4,
      year: 2023,
      Beef: 7000,
      Chicken: 8000,
      Salmon: 3400,
      Corn: 5000,
    },
    {
      day: 15,
      month: 4,
      year: 2023,
      Beef: 9000,
      Chicken: 10000,
      Salmon: 4400,
      Corn: 6600,
    },
    {
      day: 16,
      month: 4,
      year: 2023,
      Beef: 8200,
      Chicken: 9200,
      Salmon: 4100,
      Corn: 6100,
    },
    {
      day: 17,
      month: 4,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 18,
      month: 4,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 19,
      month: 4,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 20,
      month: 4,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 21,
      month: 4,
      year: 2023,
      Beef: 7200,
      Chicken: 8200,
      Salmon: 3600,
      Corn: 5200,
    },
    {
      day: 22,
      month: 4,
      year: 2023,
      Beef: 9500,
      Chicken: 10500,
      Salmon: 4800,
      Corn: 7000,
    },
    {
      day: 23,
      month: 4,
      year: 2023,
      Beef: 8000,
      Chicken: 9000,
      Salmon: 4000,
      Corn: 6000,
    },
    {
      day: 24,
      month: 4,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 25,
      month: 4,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 26,
      month: 4,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 27,
      month: 4,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 28,
      month: 4,
      year: 2023,
      Beef: 7000,
      Chicken: 8000,
      Salmon: 3400,
      Corn: 5000,
    },
    {
      day: 29,
      month: 4,
      year: 2023,
      Beef: 9000,
      Chicken: 10000,
      Salmon: 4400,
      Corn: 6600,
    },
    {
      day: 30,
      month: 4,
      year: 2023,
      Beef: 8200,
      Chicken: 9200,
      Salmon: 4100,
      Corn: 6100,
    },
    {
      day: 1,
      month: 5,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 2,
      month: 5,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 3,
      month: 5,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 4,
      month: 5,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 5,
      month: 5,
      year: 2023,
      Beef: 7200,
      Chicken: 8200,
      Salmon: 3600,
      Corn: 5200,
    },
    {
      day: 6,
      month: 5,
      year: 2023,
      Beef: 9500,
      Chicken: 10500,
      Salmon: 4800,
      Corn: 7000,
    },
    {
      day: 7,
      month: 5,
      year: 2023,
      Beef: 8000,
      Chicken: 9000,
      Salmon: 4000,
      Corn: 6000,
    },
    {
      day: 8,
      month: 5,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
    {
      day: 9,
      month: 5,
      year: 2023,
      Beef: 9200,
      Chicken: 10200,
      Salmon: 4600,
      Corn: 6800,
    },
    {
      day: 10,
      month: 5,
      year: 2023,
      Beef: 7800,
      Chicken: 8800,
      Salmon: 3900,
      Corn: 5800,
    },
    {
      day: 11,
      month: 5,
      year: 2023,
      Beef: 8500,
      Chicken: 9500,
      Salmon: 4200,
      Corn: 6200,
    },
    {
      day: 12,
      month: 5,
      year: 2023,
      Beef: 7000,
      Chicken: 8000,
      Salmon: 3400,
      Corn: 5000,
    },
    {
      day: 13,
      month: 5,
      year: 2023,
      Beef: 9000,
      Chicken: 10000,
      Salmon: 4400,
      Corn: 6600,
    },
    {
      day: 14,
      month: 5,
      year: 2023,
      Beef: 8200,
      Chicken: 9200,
      Salmon: 4100,
      Corn: 6100,
    },
    {
      day: 15,
      month: 5,
      year: 2023,
      Beef: 7500,
      Chicken: 8500,
      Salmon: 3800,
      Corn: 5500,
    },
  ],
};

const inventoryData = {
  unitMeasure: [
    { bread: "flat" },
    { eggs: "flat" },
    { pork: "lbs" },
    { beef: "lbs" },
  ],
  data: [
    {
      day: 1,
      month: 1,
      year: 2023,
      bread: 8000,
      eggs: 9000,
      pork: 4000,
      beef: 6000,
    },
    {
      day: 2,
      month: 1,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 3,
      month: 1,
      year: 2023,
      bread: 9200,
      eggs: 10000,
      pork: 4500,
      beef: 6800,
    },
    {
      day: 4,
      month: 1,
      year: 2023,
      bread: 6800,
      eggs: 7800,
      pork: 3200,
      beef: 4800,
    },
    {
      day: 5,
      month: 1,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 6,
      month: 1,
      year: 2023,
      bread: 7200,
      eggs: 8200,
      pork: 3600,
      beef: 5200,
    },
    {
      day: 7,
      month: 1,
      year: 2023,
      bread: 9500,
      eggs: 10500,
      pork: 4800,
      beef: 7000,
    },
    {
      day: 8,
      month: 1,
      year: 2023,
      bread: 8200,
      eggs: 9200,
      pork: 4100,
      beef: 6100,
    },
    {
      day: 9,
      month: 1,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 10,
      month: 1,
      year: 2023,
      bread: 9000,
      eggs: 10000,
      pork: 4400,
      beef: 6600,
    },
    {
      day: 11,
      month: 1,
      year: 2023,
      bread: 7000,
      eggs: 8000,
      pork: 3400,
      beef: 5000,
    },
    {
      day: 12,
      month: 1,
      year: 2023,
      bread: 8800,
      eggs: 9800,
      pork: 4300,
      beef: 6400,
    },
    {
      day: 13,
      month: 1,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3700,
      beef: 5500,
    },
    {
      day: 14,
      month: 1,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 15,
      month: 1,
      year: 2023,
      bread: 8000,
      eggs: 9000,
      pork: 4000,
      beef: 6000,
    },
    {
      day: 16,
      month: 1,
      year: 2023,
      bread: 7200,
      eggs: 8200,
      pork: 3600,
      beef: 5200,
    },
    {
      day: 17,
      month: 1,
      year: 2023,
      bread: 9500,
      eggs: 10500,
      pork: 4800,
      beef: 7000,
    },
    {
      day: 18,
      month: 1,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 19,
      month: 1,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 20,
      month: 1,
      year: 2023,
      bread: 7000,
      eggs: 8000,
      pork: 3400,
      beef: 5000,
    },
    {
      day: 21,
      month: 1,
      year: 2023,
      bread: 9000,
      eggs: 10000,
      pork: 4400,
      beef: 6600,
    },
    {
      day: 22,
      month: 1,
      year: 2023,
      bread: 8200,
      eggs: 9200,
      pork: 4100,
      beef: 6100,
    },
    {
      day: 23,
      month: 1,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 24,
      month: 1,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 25,
      month: 1,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 26,
      month: 1,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 27,
      month: 1,
      year: 2023,
      bread: 7200,
      eggs: 8200,
      pork: 3600,
      beef: 5200,
    },
    {
      day: 28,
      month: 1,
      year: 2023,
      bread: 9500,
      eggs: 10500,
      pork: 4800,
      beef: 7000,
    },
    {
      day: 29,
      month: 1,
      year: 2023,
      bread: 8000,
      eggs: 9000,
      pork: 4000,
      beef: 6000,
    },
    {
      day: 30,
      month: 1,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 31,
      month: 1,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 1,
      month: 2,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 2,
      month: 2,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 3,
      month: 2,
      year: 2023,
      bread: 7000,
      eggs: 8000,
      pork: 3400,
      beef: 5000,
    },
    {
      day: 4,
      month: 2,
      year: 2023,
      bread: 9000,
      eggs: 10000,
      pork: 4400,
      beef: 6600,
    },
    {
      day: 5,
      month: 2,
      year: 2023,
      bread: 8200,
      eggs: 9200,
      pork: 4100,
      beef: 6100,
    },
    {
      day: 6,
      month: 2,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 7,
      month: 2,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 8,
      month: 2,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 9,
      month: 2,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 10,
      month: 2,
      year: 2023,
      bread: 7200,
      eggs: 8200,
      pork: 3600,
      beef: 5200,
    },
    {
      day: 11,
      month: 2,
      year: 2023,
      bread: 9500,
      eggs: 10500,
      pork: 4800,
      beef: 7000,
    },
    {
      day: 12,
      month: 2,
      year: 2023,
      bread: 8000,
      eggs: 9000,
      pork: 4000,
      beef: 6000,
    },
    {
      day: 13,
      month: 2,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 14,
      month: 2,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 15,
      month: 2,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 16,
      month: 2,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 17,
      month: 2,
      year: 2023,
      bread: 7000,
      eggs: 8000,
      pork: 3400,
      beef: 5000,
    },
    {
      day: 18,
      month: 2,
      year: 2023,
      bread: 9000,
      eggs: 10000,
      pork: 4400,
      beef: 6600,
    },
    {
      day: 19,
      month: 2,
      year: 2023,
      bread: 8200,
      eggs: 9200,
      pork: 4100,
      beef: 6100,
    },
    {
      day: 20,
      month: 2,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 21,
      month: 2,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 22,
      month: 2,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 23,
      month: 2,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 24,
      month: 2,
      year: 2023,
      bread: 7200,
      eggs: 8200,
      pork: 3600,
      beef: 5200,
    },
    {
      day: 25,
      month: 2,
      year: 2023,
      bread: 9500,
      eggs: 10500,
      pork: 4800,
      beef: 7000,
    },
    {
      day: 26,
      month: 2,
      year: 2023,
      bread: 8000,
      eggs: 9000,
      pork: 4000,
      beef: 6000,
    },
    {
      day: 27,
      month: 2,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 28,
      month: 2,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 1,
      month: 3,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 2,
      month: 3,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 3,
      month: 3,
      year: 2023,
      bread: 7000,
      eggs: 8000,
      pork: 3400,
      beef: 5000,
    },
    {
      day: 4,
      month: 3,
      year: 2023,
      bread: 9000,
      eggs: 10000,
      pork: 4400,
      beef: 6600,
    },
    {
      day: 5,
      month: 3,
      year: 2023,
      bread: 8200,
      eggs: 9200,
      pork: 4100,
      beef: 6100,
    },
    {
      day: 6,
      month: 3,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 7,
      month: 3,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 8,
      month: 3,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 9,
      month: 3,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 10,
      month: 3,
      year: 2023,
      bread: 7200,
      eggs: 8200,
      pork: 3600,
      beef: 5200,
    },
    {
      day: 11,
      month: 3,
      year: 2023,
      bread: 9500,
      eggs: 10500,
      pork: 4800,
      beef: 7000,
    },
    {
      day: 12,
      month: 3,
      year: 2023,
      bread: 8000,
      eggs: 9000,
      pork: 4000,
      beef: 6000,
    },
    {
      day: 13,
      month: 3,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 14,
      month: 3,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 15,
      month: 3,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 16,
      month: 3,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 17,
      month: 3,
      year: 2023,
      bread: 7000,
      eggs: 8000,
      pork: 3400,
      beef: 5000,
    },
    {
      day: 18,
      month: 3,
      year: 2023,
      bread: 9000,
      eggs: 10000,
      pork: 4400,
      beef: 6600,
    },
    {
      day: 19,
      month: 3,
      year: 2023,
      bread: 8200,
      eggs: 9200,
      pork: 4100,
      beef: 6100,
    },
    {
      day: 20,
      month: 3,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 21,
      month: 3,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 22,
      month: 3,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 23,
      month: 3,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 24,
      month: 3,
      year: 2023,
      bread: 7200,
      eggs: 8200,
      pork: 3600,
      beef: 5200,
    },
    {
      day: 25,
      month: 3,
      year: 2023,
      bread: 9500,
      eggs: 10500,
      pork: 4800,
      beef: 7000,
    },
    {
      day: 26,
      month: 3,
      year: 2023,
      bread: 8000,
      eggs: 9000,
      pork: 4000,
      beef: 6000,
    },
    {
      day: 27,
      month: 3,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 28,
      month: 3,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 29,
      month: 3,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 30,
      month: 3,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 31,
      month: 3,
      year: 2023,
      bread: 7000,
      eggs: 8000,
      pork: 3400,
      beef: 5000,
    },
    {
      day: 1,
      month: 4,
      year: 2023,
      bread: 9000,
      eggs: 10000,
      pork: 4400,
      beef: 6600,
    },
    {
      day: 2,
      month: 4,
      year: 2023,
      bread: 8200,
      eggs: 9200,
      pork: 4100,
      beef: 6100,
    },
    {
      day: 3,
      month: 4,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 4,
      month: 4,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 5,
      month: 4,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 6,
      month: 4,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 7,
      month: 4,
      year: 2023,
      bread: 7200,
      eggs: 8200,
      pork: 3600,
      beef: 5200,
    },
    {
      day: 8,
      month: 4,
      year: 2023,
      bread: 9500,
      eggs: 10500,
      pork: 4800,
      beef: 7000,
    },
    {
      day: 9,
      month: 4,
      year: 2023,
      bread: 8000,
      eggs: 9000,
      pork: 4000,
      beef: 6000,
    },
    {
      day: 10,
      month: 4,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 11,
      month: 4,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 12,
      month: 4,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 13,
      month: 4,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 14,
      month: 4,
      year: 2023,
      bread: 7000,
      eggs: 8000,
      pork: 3400,
      beef: 5000,
    },
    {
      day: 15,
      month: 4,
      year: 2023,
      bread: 9000,
      eggs: 10000,
      pork: 4400,
      beef: 6600,
    },
    {
      day: 16,
      month: 4,
      year: 2023,
      bread: 8200,
      eggs: 9200,
      pork: 4100,
      beef: 6100,
    },
    {
      day: 17,
      month: 4,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 18,
      month: 4,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 19,
      month: 4,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 20,
      month: 4,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 21,
      month: 4,
      year: 2023,
      bread: 7200,
      eggs: 8200,
      pork: 3600,
      beef: 5200,
    },
    {
      day: 22,
      month: 4,
      year: 2023,
      bread: 9500,
      eggs: 10500,
      pork: 4800,
      beef: 7000,
    },
    {
      day: 23,
      month: 4,
      year: 2023,
      bread: 8000,
      eggs: 9000,
      pork: 4000,
      beef: 6000,
    },
    {
      day: 24,
      month: 4,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 25,
      month: 4,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 26,
      month: 4,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 27,
      month: 4,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 28,
      month: 4,
      year: 2023,
      bread: 7000,
      eggs: 8000,
      pork: 3400,
      beef: 5000,
    },
    {
      day: 29,
      month: 4,
      year: 2023,
      bread: 9000,
      eggs: 10000,
      pork: 4400,
      beef: 6600,
    },
    {
      day: 30,
      month: 4,
      year: 2023,
      bread: 8200,
      eggs: 9200,
      pork: 4100,
      beef: 6100,
    },
    {
      day: 1,
      month: 5,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 2,
      month: 5,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 3,
      month: 5,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 4,
      month: 5,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 5,
      month: 5,
      year: 2023,
      bread: 7200,
      eggs: 8200,
      pork: 3600,
      beef: 5200,
    },
    {
      day: 6,
      month: 5,
      year: 2023,
      bread: 9500,
      eggs: 10500,
      pork: 4800,
      beef: 7000,
    },
    {
      day: 7,
      month: 5,
      year: 2023,
      bread: 8000,
      eggs: 9000,
      pork: 4000,
      beef: 6000,
    },
    {
      day: 8,
      month: 5,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
    {
      day: 9,
      month: 5,
      year: 2023,
      bread: 9200,
      eggs: 10200,
      pork: 4600,
      beef: 6800,
    },
    {
      day: 10,
      month: 5,
      year: 2023,
      bread: 7800,
      eggs: 8800,
      pork: 3900,
      beef: 5800,
    },
    {
      day: 11,
      month: 5,
      year: 2023,
      bread: 8500,
      eggs: 9500,
      pork: 4200,
      beef: 6200,
    },
    {
      day: 12,
      month: 5,
      year: 2023,
      bread: 7000,
      eggs: 8000,
      pork: 3400,
      beef: 5000,
    },
    {
      day: 13,
      month: 5,
      year: 2023,
      bread: 9000,
      eggs: 10000,
      pork: 4400,
      beef: 6600,
    },
    {
      day: 14,
      month: 5,
      year: 2023,
      bread: 8200,
      eggs: 9200,
      pork: 4100,
      beef: 6100,
    },
    {
      day: 15,
      month: 5,
      year: 2023,
      bread: 7500,
      eggs: 8500,
      pork: 3800,
      beef: 5500,
    },
  ],
};

const InventoryDashboard = () => {
  return (
    <Dashboard>
      <FourCardRow>
        <InfoCard
          primaryText="Total Inventory Value"
          secondaryText="$15,000"
          icon={clipboard}
        />
        <InfoCard
          primaryText="Low Stock Items"
          secondaryText="5 Items"
          icon={lowStock2}
        />
        <InfoCard
          primaryText="Incoming Shipments"
          secondaryText="3 Orders"
          icon={shoppingCart}
        />
        <InfoCard
          primaryText="Inventory Turnover Rate"
          secondaryText="90%"
          icon={calendarSoon}
        />
      </FourCardRow>
      <FourGraphs>
        <ContentCard title="Inventory Value by Category">
          <SimplePieChart data={pieChartData} />
        </ContentCard>
        <ContentCard title="Low Stock Items" showDateDropdown={false}>
          <SimpleTableChartTwo />
          {/*<LineBarChart data={lineBarChartData} barName={"Inventory Value"} lineName={"Forecast next month: $20,000"}/>*/}
        </ContentCard>
        <ContentCard title="Incoming Shipments" showDateDropdown={true}>
          {/*<CustomBarChart data={customBarChartData}/>*/}
          <CustomStackedBarChart />
        </ContentCard>
        <ContentCard title="Inventory Turnover Rate" showDateDropdown={true}>
          {/*<SingleLineChart*/}
          {/*  data={generateLineChartData(*/}
          {/*    new Date(2023, 0, 1),*/}
          {/*    12,*/}
          {/*    'Inventory Turnover Rate',*/}
          {/*    4.5,     // Starting with 4.5 turns per year*/}
          {/*    0.1*/}
          {/*  )}*/}
          {/*  label="Inventory Turnover Rate"*/}
          {/*  yAxisUnit="turns"*/}
          {/*  color="#2196f3"*/}
          {/*/>*/}
          <CustomInventoryTurnoverChart />
        </ContentCard>
      </FourGraphs>
    </Dashboard>
  );
};

export default InventoryDashboard;
