import React from 'react';
import { useField } from 'formik';
import { TextField, MenuItem } from '@mui/material';
import FieldWrapper from './FieldWrapper';
import {useTheme} from "@mui/material/styles";

const StyledSelect = ({ label, options, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const theme = useTheme();

  return (
    <FieldWrapper label={label}>
      <TextField
        {...field}
        {...props}
        select
        fullWidth
        helperText={errorText}
        error={!!errorText}
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: '#F3F4F6', // Light gray background
            borderRadius: '0.75rem',
            fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#E5E7EB', // Very light grey when not selected
            },
            '&:hover fieldset': {
              borderColor: '#D1D5DB',
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.primary.main, // Using the theme's primary color
            },
          },
          '& .MuiInputBase-input': {
            color: '#4D5658', // As per Figma file
            fontSize: '0.8rem', // Matching body1 from theme
          },
          '& .MuiFormHelperText-root': {
            fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
            fontSize: '0.7rem',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FieldWrapper>
  );
};

export default StyledSelect;