import React, { useState, useEffect, useRef } from "react";
import TableComponent from "../../../components/common/TableComponent";
import PaginationComponent from "../../../components/common/Pagination";
import CustomWideLayout from "../../../components/common/Layout/CustomWideLayout";
import { useNavigate } from "react-router-dom";
import axios from "../../../api";
import EquipmentModal from "../../../components/modals/EquipmentModal/equipmentModal";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { toast } from "react-toastify";

const EquipmentManagement = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [equipments, setEquipments] = useState([]);
  const [open, setOpen] = useState(false);
  const [btnValue, setBtnValue] = useState("add");
  const [selectedRow, setSelectedRow] = useState(null);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(true);
  const [selectedChildIndex, setSelectedChildIndex] = useState(null);

  const tableHeaders = [
    { id: "Equipment_Name", label: "Equipment Name" },
    { id: "Equipment_BarCode", label: "Equipment Bar Code" },
    { id: "Model_Number", label: "Model Number" },
    { id: "Next_Cleaning_Date", label: "Next Cleaning Date" },
    { id: "Next_Maintenance_Date", label: "Next Maintenance Date" },
    { id: "Operational_Status", label: "Operational Status" },
    { id: "more", label: "" },
  ];

  const handleEdit = (row) => {
    navigate("/production/equipment-management/edit", {
      state: { id: row["EquipmentID"] },
    });
  };

  const handleDelete = (row) => {
    setOpenConfirmation(true);
    setSelectedChildIndex(row["EquipmentID"]);
  };

  const handleChange = (search) => {
    setSearchTerm(search);
    setCurrentPage(1);
  };

  useEffect(() => {
    const calculateRowsPerPage = () => {
      const availableHeight = window.innerHeight - 371;
      const rowHeight = 72;
      const rowGap = 20;
      const rows = Math.floor(availableHeight / (rowHeight + rowGap)) - 1;
      setRowsPerPage(rows > 0 ? rows : 10);
    };

    calculateRowsPerPage();

    window.addEventListener("resize", calculateRowsPerPage);

    return () => {
      window.removeEventListener("resize", calculateRowsPerPage);
    };
  }, []);

  const handleAddClick = () => {
    setBtnValue("add");
    setSelectedRow(null);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getEquipments = async () => {
    if (rowsPerPage) {
      axios
        .get(
          `/equipments?page=${currentPage}&keyword=${searchTerm}&size=${rowsPerPage}`
        )
        .then((res) => {
          let data = res.data.data.data;
          data = data.map((item) => {
            return {
              EquipmentID: item.EquipmentID,
              Equipment_Name: item.Name,
              Equipment_BarCode: item.Equipment_BarCode,
              Model_Number: item.Model_Number,
              Description: item.Description,
              Purchase_Date: new Date(item.Purchase_Date)
                .toISOString()
                .split("T")[0],
              Warranty_Date: new Date(item.Warranty_Date)
                .toISOString()
                .split("T")[0],
              Image_Location: item.Image_Location,
              Equipment_TypeID: item.Equipment_TypeID,
              Last_Maint_Date: new Date(item.Last_Maint_Date)
                .toISOString()
                .split("T")[0],
              Operational_Status: item.Operational_Status,
            };
          });
          setEquipments(data);
          setTotalPage(res.data.data.totalPages);
        });
    }
  };

  useEffect(() => {
    getEquipments();
  }, [currentPage, rowsPerPage, searchTerm, refreshFlag]);

  const deleteHandler = () => {
    try {
      const url = `/equipments/${selectedChildIndex}`;
      const message = "Equipment Deleted Successfully";

      axios.delete(url).then((res) => {
        toast.success(message);
        setOpenConfirmation(false);
        setSelectedChildIndex(null);
        getEquipments();
      });
    } catch (error) {
      console.error("Error deleting job:", error);
      toast.error("Failed to delete job. Please try again.");
    }
  };

  return (
    <>
      <CustomWideLayout>
        <TableComponent
          tableHeaders={tableHeaders}
          data={equipments}
          setData={setEquipments}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onAddText="Add New Equipment"
          circleName="Equipment_Name"
          onAddClick={handleAddClick}
          searchPlaceholder="Search Equipment"
          onSearch={handleChange}
        />
      </CustomWideLayout>
      <PaginationComponent
        currentPage={currentPage}
        totalPage={totalPage}
        setCurrentPage={setCurrentPage}
      />

      {open && (
        <EquipmentModal
          open={open}
          handleClose={onClose}
          btnValue={btnValue}
          id={selectedRow?.ReportID}
          onSubmit={setRefreshFlag}
        />
      )}

      {openConfirmation && selectedChildIndex && (
        <ConfirmationModal
          type={"delete"}
          open={openConfirmation}
          onClose={() => {
            setOpenConfirmation(false);
            setSelectedChildIndex(null);
          }}
          onSubmit={deleteHandler}
          from={"equipment"}
        />
      )}
    </>
  );
};

export default EquipmentManagement;
