// api.routes.js

export const API_ROUTES = {
  GET_ALL_NOTIFICATIONS: "/notifications/getAll",
  CREATE_NOTIFICATION: "/api/notifications/create",
  GET_NOTIFICATION_BY_ID: "/api/notifications/get/:id",
  GET_NOTIFICATION_BY_GROUP: "/api/notifications/group/:group",
  UPDATE_NOTIFICATION: "/notifications",
  DELETE_NOTIFICATION: "/api/notifications/delete/:id",
};
