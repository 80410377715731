import React from 'react';
import { Grid, Typography } from '@mui/material';

const FieldWrapper = ({ label, children }) => (
  <Grid container alignItems="center" spacing={2}>
    <Grid item xs={4}>
      <Typography>{label}</Typography>
    </Grid>
    <Grid item xs={8}>
      {children}
    </Grid>
  </Grid>
);

export default FieldWrapper;