import React, {useState, useEffect} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  PaginationItem,
  TextField,
  Button, styled, InputAdornment, IconButton, Menu, MenuItem, MenuList, ListItemIcon,
} from "@mui/material";
import {ArrowBack, ArrowForward, Search, MoreVert, Edit, Delete, MoreHoriz} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import editIcon from "../../../assets/images/editIcon.svg";
import trashIcon from "../../../assets/images/trashIcon.svg";
import {useTheme} from "@mui/material/styles";


const CustomTable = (
  {
    data = [],
    onEdit,
    onDelete,
    onAddClick = null,
    onAddText = null,
    rowsPerPage = 10,
    showSearch = true,
    searchText = "Search..."
  }
) => {
  // For filtering and pagination
  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  // For popup Menu
  const [clickedRowPos, setClickedRowPos] = useState({ x: 0, y: 0});
  const [selectedRow, setSelectedRow] = useState(null);

  const theme = useTheme()

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (clickedRowPos) {
        setClickedRowPos(null);
        setSelectedRow(null)
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [clickedRowPos]);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = data.filter((row) =>
      Object.values(row).some(
        (value) => value && value.toString().toLowerCase().includes(term)
      )
    );
    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const getCurrentPageData = () => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredData.slice(start, end);
  };

  const handleMenuClick = (event, row) => {
    event.preventDefault();
    event.stopPropagation();

    const buttonElement = event.currentTarget;
    const rect = buttonElement.getBoundingClientRect();

    setClickedRowPos({
      x: rect.left - 160,
      y: rect.top + window.scrollY,
    });
    setSelectedRow(row);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: theme.spacing(2),
    boxShadow: 'none',
    backgroundColor: 'transparent',
    marginBottom: '1rem'
  }));

  const StyledTable = styled(Table)(({ theme }) => ({
    borderCollapse: 'separate',
    borderSpacing: '0 16px', // This creates space between rows
  }));

  const StyledHeaderCell = styled(TableCell)(({ theme, isFirst, isLast }) => ({
    color: '#143664',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '1.15rem',
    borderWidth: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.secondary,
    '&:first-of-type': {
      borderTopLeftRadius: isFirst ? '20px' : 0,
      borderBottomLeftRadius: isFirst ? '20px' : 0,
    },
    '&:last-child': {
      borderTopRightRadius: isLast ? '20px' : 0,
      borderBottomRightRadius: isLast ? '20px' : 0,
    },
  }));

  const StyledBodyRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: 'white',
  }));

  const StyledBodyCell = styled(TableCell)(({ theme, isFirst, isLast }) => ({
    borderWidth: 0,
    padding: theme.spacing(2),
    '&:first-of-type': {
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: '20px',
    },
    '&:last-child': {
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
    },
    '&:not(:last-child)': {
      position: 'relative',
      '&::after': {
        content: isLast ? 'none' : '""', // Don't show separator for last cell
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        height: '18px',
        width: '1px',
        backgroundColor: '#888888',
      },
    },
  }));


  const StyledMenuList = styled(MenuList)(({ theme }) => ({
    backgroundColor: 'white',
    borderRadius: '12px', // Adjusted to match original
    padding: '16px',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    '& .MuiMenuItem-root': {
      padding: '8px',
      borderRadius: '8px',
      ...theme.typography.label1,
      color: '#2D3748', // Adjusted text color to match original
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
  }));

  const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    backgroundColor: theme.palette.tertiary.main,
    borderRadius: '12px', // Adjusted to match original
    width: '40px', // Adjusted size to match original
    height: '40px', // Keeping it square
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '12px', // Slightly increased for better spacing
    '& img': {
      width: '20px', // Adjusted icon size to match original
      height: '20px',
    },
  }));

  return (
    <>
      {(onAddText || showSearch) && (  // Only render if either is needed
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '.75rem', marginTop: '2rem' }}>
          {onAddText && (
            <Button
              variant="contained"
              onClick={onAddClick}
              sx={{
                borderRadius: '12px',
                padding: '8px 28px',
                minHeight: '48px',
                textTransform: 'none'
              }}
            >
              {onAddText}
            </Button>
          )}

          {showSearch && (
            <TextField
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '350px',
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                  borderRadius: '12px',
                  '& fieldset': {
                    border: 'none',
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '16px 14px',
                  }
                }
              }}
            />
          )}
        </div>
      )}

      <StyledTableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow>
              {Object.keys(data[0] || {}).map((key, index, array) => (
                <StyledHeaderCell
                  key={key}
                  isFirst={index === 0}
                  isLast={index === array.length - 1}
                >
                  {key.replace(/_/g, ' ')}
                </StyledHeaderCell>
              ))}
              <StyledHeaderCell isLast>Actions</StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getCurrentPageData().map((row, rowIndex) => (
              <StyledBodyRow key={rowIndex}>
                {Object.values(row).map((value, cellIndex, array) => (
                  <StyledBodyCell
                    key={cellIndex}
                    isFirst={cellIndex === 0}
                    isLast={cellIndex === array.length - 1}
                  >
                    {value}
                  </StyledBodyCell>
                ))}
                <StyledBodyCell isLast>
                  <IconButton
                    onClick={(e) => handleMenuClick(e, row)}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#F5F8FA',  // Light blue/gray background on hover
                        '& .MuiSvgIcon-root': {
                          color: theme.palette.tertiary.main,  // Same blue as icon backgrounds
                        }
                      },
                      borderRadius: '8px',  // Optional: adds a subtle rounded corner on hover
                    }}
                  >
                    <MoreHoriz sx={{
                      color: selectedRow === row ? theme.palette.tertiary.main : '#666666',
                      fontSize: '28px'  // Increased icon size
                    }} />
                  </IconButton>
                </StyledBodyCell>
              </StyledBodyRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>

      <Stack
        spacing={2}
        className="h-14 flex text-center w-full justify-center"
        sx={{
          '& .MuiPagination-ul': {
            margin: 0,
            padding: 0,
            '& .MuiPaginationItem-root': {
              margin: 0,
              padding: 0
            }
          },
          marginBottom: '1rem'
        }}
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          siblingCount={1}
          onChange={(e, page) => setCurrentPage(page)}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              components={{
                previous: () => (
                  <div className="bg-BtnBg px-6 py-3 rounded-xl flex items-center gap-1 hover:bg-opacity-80 transition-all mr-auto">
                    <ArrowBack sx={{ fontSize: 12, color: "#fff" }} />
                    <span className="text-base font-normal text-BtnText">
                Previous
              </span>
                  </div>
                ),
                next: () => (
                  <div className="bg-BtnBg px-6 py-3 rounded-xl flex items-center gap-1 hover:bg-opacity-80 transition-all ml-auto">
              <span className="text-base font-normal text-BtnText">
                Next
              </span>
                    <ArrowForward sx={{ fontSize: 12, color: "#fff" }} />
                  </div>
                ),
              }}
            />
          )}
        />
      </Stack>

      {clickedRowPos && (
        <div
          className="absolute z-50"
          style={{
            left: clickedRowPos.x,
            top: clickedRowPos.y,
          }}
        >
          <StyledMenuList>
            <MenuItem
              onClick={() => {
                onEdit(selectedRow);
                setClickedRowPos(null);
              }}
            >
              <StyledListItemIcon>
                <img src={editIcon} alt="Edit" />
              </StyledListItemIcon>
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                onDelete(selectedRow);
                setClickedRowPos(null);
              }}
            >
              <StyledListItemIcon>
                <img src={trashIcon} alt="Delete" />
              </StyledListItemIcon>
              Delete
            </MenuItem>
          </StyledMenuList>
        </div>
      )}
    </>
  );
};

export default CustomTable;