import React from "react";
import { Link, useLocation } from "react-router-dom";
import arrowForward from "../../../assets/images/arrowForward.svg";

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const formatBreadcrumb = (str) => {
    return str
      .replace(/-/g, " ") // Replace dashes with spaces
      .replace(/\b\w/g, (l) => l.toUpperCase()); // Capitalize each word
  };

  // Filter out segments that match the :id pattern
  const filteredPathnames = pathnames.filter((segment) => !/^\d+$/.test(segment));

  return (
    <nav className="flex items-center text-white text-base pt-2">
      <Link to="/" className="text-white hover:text-[#1479FF] capitalize">
        Dashboard
      </Link>
      {filteredPathnames.map((value, index) => {
        const to = `/${filteredPathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === filteredPathnames.length - 1;

        return (
          <span key={to} className="flex items-center">
            {/* {index !== 0 && ( */}
              <img
                className="mx-4 w-[10px] h-auto"
                src={arrowForward}
                alt="arrowForward"
              />
            {/* )} */}
            {isLast ? (
              <span className="text-[#1479FF] capitalize font-bold text-[17px]">
                {formatBreadcrumb(value)}
              </span>
            ) : (
              <Link
                to={to}
                className="text-white hover:text-[#1479FF] capitalize"
              >
                {formatBreadcrumb(value)}
              </Link>
            )}
          </span>
        );
      })}
    </nav>
  );
};

export default Breadcrumb;
