import React from "react";

const ComingSoon = () => {
  return (
    <div className="flex items-center justify-center w-full max-h-screen mt-52">
      <p className="text-[7rem] font-[700] text-[#b9b7b2] ">Coming Soon...</p>
    </div>
  );
};

export default ComingSoon;
