import React from "react";
import { Box, Grid, Container } from "@mui/material";

const Dashboard = ({ children }) => {
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          p: 3,
          boxShadow: 1,
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default Dashboard;
